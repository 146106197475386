export default {
	talkingPoint: 'TalkingPoint',
	login: 'login',
	connection: 'connection',
	downloadReport: 'Download Report',
	exportReport: 'Export Report',
	export: 'export',
	internal: 'internal',
	external: 'external',
	exportCollateral: 'Export Collateral',
	exportMethodology: 'Export Methodology',
	filter: 'filter',
	federatedSearchFilter: 'federatedSearchFilter',
	federatedSearchSurvey: 'federatedSearchSurveysClicked',
	showResult: 'show results',
	experience: 'experience',
	explorePageFilters: 'explorePageFilter',
	explorePageSurveys: 'explorePageSurveysClicked'
};

<template>
	<v-menu
		v-model="menu"
		max-height="150px"
		offset-y
		v-if="value"
	>
		<template v-slot:activator="{ on, attrs }">
			<div
				v-bind="attrs"
				v-on="on"
				class="filter"
			>
				<FiltersButton
					:label="label || valueSelected[text]"
					:showFilters="menu"
				></FiltersButton>
			</div>
		</template>
		<ul class="filters-button-list">
			<li
				v-for="(item, idx) in items" :key="idx"
				:class="{ _active: isActive(item) }"
				@click="change(item)">{{ item[text] }}</li>
		</ul>
	</v-menu>
</template>

<script>

import FiltersButton from '@/components/FiltersButton';

export default {

	components: {
		FiltersButton
	},

	props: {
		items: {
			type: Array,
			required: true
		},
		value: {
			type: [Object, Array],
			default: null
		},
		text: {
			type: String,
			default: 'text'
		},
		id: {
			type: String,
			default: 'id'
		},
		multiple: {
			type: Boolean,
			default: false
		},
		initialSelected: Number,
		label: String
	},

	data () {
		return {
			menu: false,
			valueSelected: this.initValueSelected()
		};
	},

	mounted () {
		this.$emit('input', this.valueSelected);
	},

	methods: {
		initValueSelected () {
			return this.multiple ? [] : this.items[this.initialSelected || 0];
		},
		change (item) {
			if (this.multiple) {
				if (this.isActive(item)) {
					this.valueSelected = this.valueSelected.filter((e) => e[this.id] !== item[this.id]);
				} else {
					this.valueSelected = [...this.valueSelected, item];
				}
				this.$emit('input', this.valueSelected);
			} else {
				this.$emit('input', item);
				this.valueSelected = item;
			}
		},
		isActive (item) {
			return this.multiple
				? this.valueSelected.map((e) => e[this.id]).includes(item[this.id])
				: this.valueSelected[this.id] === item[this.id];
		}
	},

	watch: {
		items (to, from) {
			this.valueSelected = this.initValueSelected();
			this.$emit('input', this.valueSelected);
		}
	}

};

</script>

<style lang="scss">
@import "@/scss/_colors.scss";

	.filters-button-list {
		background-color: white;

		li {
			cursor: pointer;
			padding: 5px 10px;
			transition: background 250ms;
			white-space: nowrap;
      color: $meta-gray;

			&._active {
				background-color: $gray-100;
			}

      &:hover {
        background: $gray-100;
      }
		}
	}

	.v-menu--attached {
		div[role="menu"] {
			left: auto !important;
			right: 0;
		}
	}
</style>

<template>
	<v-progress-circular
		indeterminate
		:size="size"
		width="2"
		value="100"
		color="metaBlue"
	></v-progress-circular>
</template>

<script>

export default {
	name: 'Spinner',

	props: {
		size: {
			type: String,
			default: '32'
		}
	}
};
</script>

<template>
	<v-snackbar class="loading__snackbar" :value="isLoading" light timeout="100000">
		<Spinner />

		{{ text }}
	</v-snackbar>
</template>

<script>

import Spinner from '../components/Spinner.vue';

export default {

	name: 'LoadingSnackbar',

	components: {
		Spinner
	},

	props: {
		text: {
			type: String,
			required: true
		},
		isLoading: {
			type: Boolean,
			default: false
		}
	}

};

</script>

<style lang="scss">

.loading__snackbar .v-snack__content {
  display: flex;
  align-items: center;
  gap: 16px
}

</style>

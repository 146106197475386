<template>
	<div class="filters-button">
		{{ filterWithCount }}
		<span class="material-icons-outlined" v-if="showFilters">expand_less</span>
		<span class="material-icons-outlined" v-else>expand_more</span>
	</div>
</template>

<script>
export default {
	name: 'FiltersButton',

	props: {
		totalSelected: Number,
		showFilters: Boolean,
		label: {
			type: String,
			default: 'Filters'
		}
	},

	computed: {
		filterWithCount: function () {
			return this.totalSelected ? `${this.label} (${this.totalSelected})` : this.label;
		}
	}

};
</script>

<style lang="scss">
@import "@/scss/_colors.scss";

.filters-button {
    display: flex;
    align-items: center;
    color: $green-06;
    font-size: 18px;
}
</style>
